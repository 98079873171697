import { Component, TemplateRef, computed, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ModalService } from './modal.service';
import { Modal } from './modal.interface';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ecommerce-modal',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent {
  customizeContent!: TemplateRef<unknown>;
  title!: string;
  description!: string;
  isOpen!: boolean;
  itemsCount!: number;
  hasCloseIcon!: boolean;
  formGroup!: FormGroup;
  canNotCloseFromOutside = false;
  modalClass = computed<string>(this.modalService.modalClass);

  constructor(private modalService: ModalService) {
    this.updateModal();
  }

  closeModal() {
    this.isOpen = false;
    if (this.formGroup) {
      this.formGroup.reset();
    }
  }

  updateModal() {
    effect(() => {
      const modal: Modal = this.modalService.modalContent();
      this.title = modal.title as string;
      this.description = modal.description as string;
      this.customizeContent = modal.customizeContent as TemplateRef<unknown>;
      this.isOpen = !!modal.isOpen;
      this.hasCloseIcon = !!modal.hasCloseIcon;
      this.formGroup = modal.formGroup as FormGroup;
      this.canNotCloseFromOutside = !!modal.canNotCloseFromOutside;

      if (this.isOpen) {
        (<HTMLHtmlElement>document.querySelector('html')).style.overflowY =
          'hidden';
      } else {
        (<HTMLHtmlElement>document.querySelector('html')).style.overflowY =
          'unset';
      }
    });
  }
}
